import React from 'react';
import './Footer.scss';
import { useStaticQuery, graphql } from "gatsby"

const Footer = ({ }) => {
  const data = useStaticQuery(graphql`
    query QueryFooter {
      allContentfulFooter {
        nodes {
          links {
            link
            label
          }
          textSetupCookies
          legalNotice {
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const footerData = data?.allContentfulFooter?.nodes[0];
  return (
    <footer>
      <div className="footer-wrap">
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">{footerData.textSetupCookies}</button>
        <a href={footerData.legalNotice.file.url} target="_blank">{footerData.legalNotice.title}</a>
        <a href={footerData.links[0].link}>{footerData.links[0].label}</a>
      </div>
    </footer>
  );
};


export default Footer;
