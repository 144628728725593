import React, { useState, useEffect } from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import './Header.scss';
import { Helmet } from 'react-helmet';
import { isBrowser } from '../../../Utils/Utils';
const Header = ({ }) => {
  const data = useStaticQuery(graphql`
     query About {
      allContentfulHeader {
        nodes {
          websiteTitle
          logo {
            title
            gatsbyImageData
          }
          menu {
            label
            externalLink
            link
          }
          socialIcons {
            label
            link
            externalLink
            icon {
              file {
                url
              }
            }
          }
        }
      }
     }
   `)
  const [showNavMobile, setShowNavMobile] = useState(false)
  const [isWorksPage, setIsWorkPage] = useState()
  const headerData = data?.allContentfulHeader?.nodes[0];
  const handleNavigation = (target) => {
    let scrollEl = document.querySelector(target.replace("/", "#"))?.offsetTop
    if (showNavMobile) {
      setShowNavMobile(false)
      window.scrollTo({ top: scrollEl, behavior: 'smooth' });

    } else {
      window.scrollTo({ top: scrollEl, behavior: 'smooth' });
    }
  }

  useEffect(() => {
    let body = document.querySelector('body');
    if (showNavMobile) {
      body.classList.add("stop-scrolling");
    } else {
      body.classList.remove("stop-scrolling");
    }
  }, [showNavMobile])

  // 

  useEffect(() => {
    if (!isBrowser) {
      return;
    }

    return setIsWorkPage(prev => window.location.pathname.includes("/works"))
  }, [])
  return (
    <>
      <Helmet>

        {/* <!-- Début de la mention OneTrust de consentement aux cookies du domaine : everydaycontent.fr --> */}
        <script type="text/javascript" src="https://cdn.cookielaw.org/consent/e8dc5c65-e05b-4b2d-9654-7aa883d67b28/OtAutoBlock.js"></script>
        <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charset="UTF-8" data-domain-script="e8dc5c65-e05b-4b2d-9654-7aa883d67b28" ></script>
        <script type="text/javascript">
          {
            `
            function OptanonWrapper() { }
            `
          }

        </script>
        {/* <!-- Fin de la mention OneTrust de consentement aux cookies du domaine : everydaycontent.fr --> */}
        {/* <!-- Matomo --> */}

        <script script type="text/plain" class="optanon-category-C0007">
          {
            `
            var _paq = window._paq = window._paq || [];
            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
            (function() {
              var u="https://matomo.publicisfrance.com/";
              _paq.push(['setTrackerUrl', u+'matomo.php']);
              _paq.push(['setSiteId', '6']);
              var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
              g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
            })();
            `
          }

        </script>
        {/* <!-- End Matomo Code --> */}
      </Helmet>
      <header className={`header black ${showNavMobile ? "show_nav_mobile" : ""} `} id="header" role="banner">
        <div className="header-container">
          <div className="left-content">
            <div className="logo">
              <a href="/">
                <GatsbyImage image={getImage(headerData.logo)} alt={headerData.logo.title} />
              </a>
            </div>
            <ul className="menu">
              {
                headerData.menu.map((menuItem, index) => (
                  <li onClick={() => handleNavigation(menuItem.link)} key={'menuitem-' + index}>

                    {
                      isWorksPage ?
                        <Link to={`/${menuItem.link.replace("/", "#")}`}>
                          {menuItem.label}
                        </Link>
                        :
                        <a>{menuItem.label}</a>
                    }
                  </li>
                ))
              }
            </ul>
          </div>
          <div className="right-content">
            <ul className="social-icons">
              {
                headerData.socialIcons.map((socialIcon, index) => (
                  <li key={'socialicons-' + index}>
                    <a href={socialIcon.link} target={socialIcon.externalLink ? '_blank' : '_self'}>
                      <img className="off" src={socialIcon.icon.file.url} alt={socialIcon.label} />
                    </a>
                  </li>
                ))
              }
            </ul>
          </div>

          <button onClick={() => setShowNavMobile(prev => !prev)} className="burger_menu" title="Navigation du site">
            <span className="line_1"></span>
            <span className="line_2"></span>
            <span className="line_3"></span>
          </button>
        </div>
      </header>
    </>
  );
};

export default Header;
