

export function formatDate(date) {
  const dateFormat = new Date(date);
  const day = dateFormat.getDate();
  const month = dateFormat.getMonth() + 1;
  return ('0' + day).slice(-2) + '/' + ('0' + month).slice(-2);
}

export function isDatePublished(date) {
  const dateFormat = new Date(date);
  return (new Date() - dateFormat) > 0 ? true : false;
}

export function youtubeParser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length === 11) ? match[7] : false;
}

export const isBrowser = () => typeof window !== "undefined"
