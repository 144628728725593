import React from 'react'
import "./HomeContact.scss"
import Logo from '../../../assets/images/logo-everyday_white.png';
import { useStaticQuery, graphql } from "gatsby"


const HomeContact = ({ }) => {
	const data = useStaticQuery(graphql`
    query QueryBlockContact {
      allContentfulHomeBlockContact {
				nodes {
					title
					email
					address {
						address
					}
				}
			}
    }
  `)

	const contactData = data?.allContentfulHomeBlockContact?.nodes[0]
	const mailLink = 'mailto:' + contactData.email;
	return (
		<section id="contact">
			<div className="block-contact inner-page">
				<div className="contact-inner">
					<div className="logo-white">
						<img src={Logo} alt="logo everyday" />
					</div>
					<div className="address">
						<h4 className="tt-4"><span>{contactData.title}</span></h4>
						<p dangerouslySetInnerHTML={{ __html: contactData.address.address }}></p>
					</div>
					<div className="email-wrap">
						<p>
							<a href={mailLink}><span>{contactData.email}</span></a>
						</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default HomeContact